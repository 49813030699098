import { colors } from "application/theme/palette";
import { RegistrationStatus } from "application/types/registrationStatus";

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const userStatuses = [
  { value: "", label: "All" },
  { value: UserStatus.ACTIVE, label: "Active" },
  { value: UserStatus.INACTIVE, label: "Deleted" },
];

export const registrationStatuses = [
  { value: RegistrationStatus.EMAIL_VERIFICATION, label: "Email Verification" },
  { value: RegistrationStatus.EMAIL_VERIFIED, label: "Phone Verification" },
  { value: RegistrationStatus.PHONE_VERIFIED, label: "Survey" },
  { value: RegistrationStatus.SURVEY_FILLED, label: "KYC" },
  { value: RegistrationStatus.KYC_VERIFIED, label: "KYC verified" },
];

export const getUserStatusColor = (status?: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return colors.green700;
    case UserStatus.INACTIVE:
      return colors.red700;
    default:
      return UserStatus.INACTIVE;
  }
};
