import {
  Investor,
  PageInvestorPageResponseDto,
  InvestorAutosuggestionsResponseDto,
  UpdateUserNameParams,
  UpdateUserPhoneParams,
  UpdateUserEmailParams,
  UpdateSurveyInfoParams,
  AutosuggestionsParamsAPI,
  InvestorsParamsAPI,
  SkipSignupStepParams,
} from "./models";
import axiosInstance from "../axiosInstance";

const UsersApi = {
  getInvestors: (params: InvestorsParamsAPI) => {
    const { page, size, sort, search, status, key } = params;
    return axiosInstance.get<PageInvestorPageResponseDto>(
      "/api/admin/investor/list",
      {
        params: {
          page,
          size,
          sort,
          ...(search ? { search } : {}),
          ...(status ? { status } : {}),
        },
        headers: {
          "X-Encryption-Key": key,
        },
      }
    );
  },
  getSearchAutosuggestions: ({
    search,
    status,
    key,
  }: AutosuggestionsParamsAPI) => {
    return axiosInstance.get<InvestorAutosuggestionsResponseDto[]>(
      "/api/admin/investor/search-autosuggestions",
      {
        params: { search, ...(status ? { status } : {}) },
        headers: {
          "X-Encryption-Key": key,
        },
      }
    );
  },
  getInvestorById: (id: number, key: string) => {
    return axiosInstance.get<Investor>(`/api/admin/investor/${id}`, {
      headers: {
        "X-Encryption-Key": key,
      },
    });
  },
  deleteInvestor: (id: number) => {
    return axiosInstance.delete(`/api/admin/investor/${id}`);
  },
  deleteInvestors: (ids: number[]) => {
    return axiosInstance.delete("/api/admin/investor", { data: ids });
  },
  getSecretKey: (key: string) => {
    return axiosInstance.get(`/api/admin/investor/encryption-key`, {
      headers: {
        "X-Encryption-Key": key,
      },
    });
  },
  updateUserName: (params: UpdateUserNameParams) => {
    const { id, ...data } = params;
    return axiosInstance.put(`/api/admin/investor/${id}/change-name`, data);
  },
  updateUserPhone: ({ id, phoneNumber }: UpdateUserPhoneParams) => {
    return axiosInstance.put(`/api/admin/investor/${id}/change-phone`, {
      phoneNumber,
    });
  },
  updateUserEmail: ({ id, email }: UpdateUserEmailParams) => {
    return axiosInstance.put(`/api/admin/investor/${id}/change-email`, {
      email,
    });
  },
  generateUserPassword: (id: number) => {
    return axiosInstance.post(`/api/admin/investor/${id}/generate-password`);
  },
  updateSurveyInformation: ({ id, data }: UpdateSurveyInfoParams) => {
    return axiosInstance.put(`/api/admin/investor/${id}/change-survey`, data);
  },
  skipSignUpStep: (params: SkipSignupStepParams) => {
    const { id, ...data } = params;
    return axiosInstance.post(
      `/api/admin/investor/${id}/skip-signup-step`,
      data
    );
  },
};

export default UsersApi;
