import { createAsyncThunk } from "@reduxjs/toolkit";

import UsersApi from "integration/api/UsersApi";
import { RequestEnum } from "application/store/request/model";
import {
  InvestorsParamsDto,
  UpdateUserNameParams,
  UpdateUserPhoneParams,
  UpdateUserEmailParams,
  UpdateSurveyInfoParams,
  GetSearchAutosuggestionsParams,
  SkipSignupStepParams,
} from "integration/api/UsersApi/models";
import {
  decryptAESKey,
  decryptName,
  encryptName,
  generateRSAKeyPair,
} from "application/helpers/encryptionWithNoKey";

class UsersActions {
  static getInvestors = createAsyncThunk(
    `users/${RequestEnum.getInvestors}`,
    async (params: InvestorsParamsDto, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();
        const response = await UsersApi.getInvestors({
          ...params,
          key: keys?.publicKeyBase64 as string,
        });

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );
        const content = await Promise.all(
          response.data.content.map(async (e) => {
            const firstName = (await decryptName(
              e.firstName as string,
              decriptedKey
            )) as string;
            const lastName = (await decryptName(
              e.lastName as string,
              decriptedKey
            )) as string;
            return { ...e, firstName, lastName };
          })
        );

        return { ...response.data, content };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getSearchAutosuggestions = createAsyncThunk(
    `users/${RequestEnum.getSearchAutosuggestions}`,
    async (params: GetSearchAutosuggestionsParams, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();
        const response = await UsersApi.getSearchAutosuggestions({
          ...params,
          key: keys?.publicKeyBase64 as string,
        });

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );

        const content = await Promise.all(
          response.data.map(async (e) => {
            const firstName = (await decryptName(
              e.firstName as string,
              decriptedKey
            )) as string;
            const lastName = (await decryptName(
              e.lastName as string,
              decriptedKey
            )) as string;
            return { ...e, firstName, lastName };
          })
        );

        return content;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteInvestor = createAsyncThunk(
    `users/${RequestEnum.deleteUser}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.deleteInvestor(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteInvestors = createAsyncThunk(
    `users/${RequestEnum.deleteUsers}`,
    async (ids: number[], { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.deleteInvestors(ids);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getInvestorById = createAsyncThunk(
    `users/${RequestEnum.getInvestorById}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();

        const response = await UsersApi.getInvestorById(
          id,
          keys?.publicKeyBase64 as string
        );

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );
        const firstName = await decryptName(
          response.data.firstName as string,
          decriptedKey
        );
        const lastName = await decryptName(
          response.data.lastName as string,
          decriptedKey
        );

        return { ...response.data, firstName, lastName };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static updateUserName = createAsyncThunk(
    `users/${RequestEnum.updateUserName}`,
    async (params: UpdateUserNameParams, { rejectWithValue }) => {
      try {
        const { firstName, lastName } = params;
        const keys = await generateRSAKeyPair();
        const response = await UsersApi.getSecretKey(
          keys?.publicKeyBase64 as string
        );

        const decriptedKey = await decryptAESKey(
          response.data,
          keys?.privateKey as CryptoKey
        );

        const encryptedFirstName = (await encryptName(
          firstName,
          decriptedKey
        )) as string;
        const encryptedLastName = (await encryptName(
          lastName,
          decriptedKey
        )) as string;

        const { data } = await UsersApi.updateUserName({
          ...params,
          firstName: encryptedFirstName,
          lastName: encryptedLastName,
        });
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static updateUserPhone = createAsyncThunk(
    `users/${RequestEnum.updateUserPhone}`,
    async (params: UpdateUserPhoneParams, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.updateUserPhone(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static updateUserEmail = createAsyncThunk(
    `users/${RequestEnum.updateUserEmail}`,
    async (params: UpdateUserEmailParams, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.updateUserEmail(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static generateUserPassword = createAsyncThunk(
    `users/${RequestEnum.generateUserPassword}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.generateUserPassword(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static updateSurveyInformation = createAsyncThunk(
    `users/${RequestEnum.generateUserPassword}`,
    async (params: UpdateSurveyInfoParams, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.updateSurveyInformation(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static skipSignUpStep = createAsyncThunk(
    `users/${RequestEnum.skipSignUpStep}`,
    async (params: SkipSignupStepParams, { rejectWithValue }) => {
      try {
        const { data } = await UsersApi.skipSignUpStep(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default UsersActions;
