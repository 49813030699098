export type RequestState = {
  [name in RequestEnum]: RequestData;
};

export interface RequestData {
  loading: boolean;
  error: string;
}

export enum RequestEnum {
  // deals
  getDeals = "getDeals",
  createDeal = "createDeal",
  deleteDeal = "deleteDeal",
  getTechnologies = "getTechnologies",
  getStages = "getStages",
  getIndustries = "getIndustries",
  getBusinessModels = "getBusinessModels",
  getBroadCategories = "getBroadCategories",
  getLostReasons = "getLostReasons",
  //deal
  getDealById = "getDealById",
  uploadLogoFile = "uploadLogoFile",
  uploadPitchDeck = "uploadPitchDeck",
  uploadAdditionalMaterials = "uploadAdditionalMaterials",
  uploadNewsletterPicture = "uploadNewsletterPicture",
  deleteLogoFile = "deleteLogoFile",
  deletePitchDeckFile = "deletePitchDeckFile",
  deleteAdditionalMaterials = "deleteAdditionalMaterials",
  deleteNewsletterPicture = "deleteNewsletterPicture",
  updateDealById = "updateDealById",
  getDealLogo = "getDealLogo",
  uploadDealLogo = "uploadDealLogo",
  deleteLogo = "deleteLogo",
  getDealInvestors = "getDealInvestors",
  getDealInvestorById = "getDealInvestorById",
  addDealInvestor = "addDealInvestor",
  sendTestEmail = "sendTestEmail",
  getAttachmentFile = "getAttachmentFile",
  //users
  deleteUser = "deleteUser",
  deleteUsers = "deleteUsers",
  getInvestors = "getInvestors",
  getSearchAutosuggestions = "getSearchAutosuggestions",
  updateUserName = "updateUserName",
  updateUserPhone = "updateUserPhone",
  updateUserEmail = "updateUserEmail",
  generateUserPassword = "generateUserPassword",
  updateSurveyInformation = "updateSurveyInformation",
  skipSignUpStep = "skipSignUpStep",
  //portfolio
  getPortfolio = "getPortfolio",
  createInvestor = "createInvestor",
  deleteInvestor = "deleteInvestor",
  deleteInvestors = "deleteInvestors",
  getInvestorById = "getInvestorById",
  changeInvestment = "changeInvestment",
  changeInvestorStatus = "changeInvestorStatus",
  addInvestmentAnalytics = "addInvestmentAnalytics",
  updateInvestmentAnalytic = "updateInvestmentAnalytic",
  deleteInvestmentAnalytic = "deleteInvestmentAnalytic",
  getPortfolioInvestmentTotal = "getPortfolioInvestmentTotal",
  //management analytics
  getAnalyticsList = "getAnalyticsList",
  uploadAnalytic = "uploadAnalytic",
  deleteAnalytic = "deleteAnalytic",
  getAnalyticByFileId = "getAnalyticByFileId",
  updateAnalyticsList = "updateAnalyticsList",
  //auth
  forgotPassword = "forgotPassword",
  confirmForgotPassword = "confirmForgotPassword",
  //referrals
  getReferrals = "getReferrals",
  addReferrals = "addReferrals",
  editReferrals = "editReferrals",
  deleteReferrals = "deleteReferrals",
  //owners
  getOwners = "getOwners",
  addOwner = "addOwner",
  deleteOwner = "deleteOwner",
  //companies
  getCompanyList = "getCompanyList",
  addCompany = "addCompany",
  getCompanyById = "getCompanyById",
  deleteCompany = "deleteCompany",
  updateCompany = "updateCompany",
  getCompanyPortfolioList = "getCompanyPortfolioList",
  getCompanyDealsList = "getCompanyDealsList",
  getCompanyInvestorsList = "getCompanyInvestorsList",
  getCompanyLogo = "getCompanyLogo",
  deleteCompanyLogo = "deleteCompanyLogo",
  uploadCompanyLogo = "uploadCompanyLogo",
  getCompanyAnalyticsList = "getCompanyAnalyticsList",
  updateCompanyAnalyticsList = "updateCompanyAnalyticsList",
  uploadCompanyAnalytic = "uploadCompanyAnalytic",
  getCompanyAnalyticByFileId = "getCompanyAnalyticByFileId",
  deleteCompanyAnalytic = "deleteCompanyAnalytic",
  getCompanySuggestions = "getCompanySuggestions",
  getCompanyTotals = "getCompanyTotals",
  //consents
  getConsentList = "getConsentList",
  getConsentById = "getConsentById",
  addConsent = "addConsent",
  deleteConsent = "deleteConsent",
  updateConsent = "updateConsent",
}
