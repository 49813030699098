import styled from "@emotion/styled";
import { getKYCStatusColor } from "application/helpers/kycStatuses";

import { colors } from "application/theme/palette";
import { KYCStatus } from "integration/api/UsersApi/models";

interface StatusProps {
  status: KYCStatus;
}

interface UserStatusProps {
  isActive: boolean;
}

export const Status = styled.p<StatusProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  font-family: "Unica77";
  text-transform: capitalize;
  color: ${({ status }) => getKYCStatusColor(status)};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:first-of-type {
      margin-right: 20px;
    }
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
  }
`;

export const UserStatusStyled = styled.p<UserStatusProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  color: ${({ isActive }) => (isActive ? colors.grey900 : colors.red700)};
`;
