import React, { FC, useMemo, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import ReactCountryFlag from "react-country-flag";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DocumentInfo from "../DocumentInfo";
import KYCStatusCard from "../KYCStatusCard";
import { formatDate } from "application/helpers/formatDate";
import { usersSelector } from "features/users/store/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import {
  UserStatus,
  userStatuses,
  registrationStatuses,
} from "application/helpers/userStatuses";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { colors } from "application/theme/palette";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import Loader from "application/components/Loader";
import { requestSelector } from "application/store/request/selectors";
import { RegistrationStatus } from "application/types/registrationStatus";

import UsersActions from "features/users/store/actions";
import { EditType } from "features/users/pages/UserDetails/types";
import EditModal from "../EditModal";

import {
  KYCItem,
  InfoItem,
  Container,
  CopyButton,
  IconStyled,
  KYCSection,
  CopyTooltip,
  CountryStyled,
  DetailSection,
  UserInfoSection,
  DetailInfoSection,
  UserStatusStyled,
  NameWrapper,
  GeneratePasswordButton,
  RegistrationStatusContainer,
  RegistrationStatusStyled,
  SkipButton,
} from "./styles";

const UserDetail: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector(usersSelector);
  const { loading } = useAppSelector(requestSelector).skipSignUpStep;
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);
  const [edit, setEdit] = useState<EditType>(EditType.empty);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSkipConfirmModal, setShowSkipConfirmModal] = useState(false);

  const showEditModal = !showErrorModal && !showSuccessModal && edit;

  const isUserActive = selected?.userStatus !== UserStatus.INACTIVE;
  const registrationStatus = registrationStatuses.find(
    (status) => status.value === selected?.registrationStatus
  );
  const isRegistrationStatusKYCVerified =
    selected?.registrationStatus === RegistrationStatus.KYC_VERIFIED;
  const isRegistrationStatusEmailVerified =
    selected?.registrationStatus === RegistrationStatus.EMAIL_VERIFIED;
  const showSkipPhoneVerification =
    isRegistrationStatusEmailVerified && !!selected?.phone?.length;
  const investorFullName = `${selected?.firstName} ${selected?.lastName}`;

  const userStatus =
    userStatuses.find((status) => status.value === selected?.userStatus)
      ?.label || UserStatus.ACTIVE;

  const successModalTitle = useMemo(() => {
    if (edit === EditType.password) {
      return `New password has been sent to the user's email.`;
    }
    const editName = edit.charAt(0).toUpperCase() + edit.slice(1);
    return `${editName} was successfully updated`;
  }, [edit]);

  const copyTextToClipboard = async (text = "") => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyEmail = () => {
    copyTextToClipboard(selected?.email).then(() => {
      setIsEmailCopied(true);
      setTimeout(() => {
        setIsEmailCopied(false);
      }, 1500);
    });
  };

  const handleCopyPhone = () => {
    copyTextToClipboard(selected?.phone).then(() => {
      setIsPhoneCopied(true);
      setTimeout(() => {
        setIsPhoneCopied(false);
      }, 1500);
    });
  };

  const handleEdit = (editItem: EditType) => {
    setEdit(editItem);
  };

  const closeEdit = () => {
    setEdit(EditType.empty);
  };

  const closeSuccessModal = () => {
    closeEdit();
    setShowSuccessModal(false);
  };

  const closeErrorModal = () => {
    closeEdit();
    setShowErrorModal(false);
  };

  // these changes may be removed in the future because many commits were skipped
  const skipSignUpStep = async () => {
    const response = await dispatch(
      UsersActions.skipSignUpStep({
        id: Number(id),
        registrationStatus: RegistrationStatus.PHONE_VERIFIED,
      })
    );
    isResponseFulfilled(response, () => {
      dispatch(UsersActions.getInvestorById(Number(id)));
      setShowSkipConfirmModal(false);
      toast.success("Investor successfully moved to Survey step");
    });
  };

  return (
    <Container>
      {loading && <Loader color={colors.grey500} />}
      <DetailSection>
        <DetailInfoSection>
          <IconStyled>
            {selected?.firstName?.charAt(0)} {selected?.lastName?.charAt(0)}
          </IconStyled>
          <div>
            <NameWrapper>
              <h1>
                {selected?.firstName} {selected?.lastName}
              </h1>
              {isUserActive && (
                <CopyButton
                  type="button"
                  onClick={() => handleEdit(EditType.name)}
                >
                  <EditSvg />
                </CopyButton>
              )}
            </NameWrapper>
            <CountryStyled>
              <ReactCountryFlag
                countryCode={selected?.country?.isoCode || ""}
              />
              <p>{selected?.country?.name}</p>
            </CountryStyled>
          </div>
        </DetailInfoSection>
        <KYCSection>
          {selected && (
            <KYCItem>
              <p>Status:</p>
              <UserStatusStyled status={selected.userStatus}>
                {userStatus}
              </UserStatusStyled>
            </KYCItem>
          )}
          {isRegistrationStatusKYCVerified ? (
            <>
              <KYCItem>
                <p>KYC status:</p>
                <KYCStatusCard status={selected?.kycStatus} />
              </KYCItem>
              <KYCItem>
                <p>KYC status date:</p>
                <h2>{formatDate(selected?.kycStatusDate)}</h2>
              </KYCItem>
            </>
          ) : (
            <>
              <KYCItem>
                <p>Start date verification:</p>
                <h2>{formatDate(selected?.startDateVerification)}</h2>
              </KYCItem>
              <RegistrationStatusContainer>
                <div>
                  <p>Sign up step of the user:</p>
                  <RegistrationStatusStyled>
                    <h2>{registrationStatus?.label}</h2>
                  </RegistrationStatusStyled>
                </div>
                {showSkipPhoneVerification && (
                  <SkipButton
                    type="button"
                    onClick={() => setShowSkipConfirmModal(true)}
                  >
                    Skip
                  </SkipButton>
                )}
              </RegistrationStatusContainer>
            </>
          )}
        </KYCSection>
      </DetailSection>
      <UserInfoSection>
        <InfoItem>
          <h3>
            <MailOutlineIcon />
            Email:
          </h3>
          <span>{selected?.email}</span>
          {isUserActive && (
            <CopyButton
              type="button"
              onClick={() => handleEdit(EditType.email)}
            >
              <EditSvg />
            </CopyButton>
          )}
          <CopyButton type="button" onClick={handleCopyEmail}>
            <LinkIcon />
            <CopyTooltip className="email-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isEmailCopied && <p>Copied!</p>}
        </InfoItem>
        <InfoItem>
          <h3>
            <LocalPhoneOutlinedIcon />
            Phone number:
          </h3>
          <h2>{selected?.phone}</h2>
          {isUserActive && (
            <CopyButton
              type="button"
              onClick={() => handleEdit(EditType.phone)}
            >
              <EditSvg />
            </CopyButton>
          )}
          <CopyButton type="button" onClick={handleCopyPhone}>
            <LinkIcon />
            <CopyTooltip className="phone-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isPhoneCopied && <p>Copied!</p>}
        </InfoItem>

        <DocumentInfo documentInfo={selected?.documentInfo} />
        {isUserActive && (
          <InfoItem>
            <h3>
              <VpnKeyOutlinedIcon />
              Password:
            </h3>
            <GeneratePasswordButton
              type="button"
              onClick={() => handleEdit(EditType.password)}
            >
              Generate new password
            </GeneratePasswordButton>
          </InfoItem>
        )}
      </UserInfoSection>
      {showEditModal && (
        <EditModal
          editType={edit}
          onClose={closeEdit}
          setShowSuccessModal={setShowSuccessModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp title={successModalTitle} onSubmit={closeSuccessModal} />
      )}
      {showErrorModal && (
        <SuccessPopUp
          title={"Failed to generate new password. Try again."}
          onSubmit={closeErrorModal}
          iconColor={colors.red700}
        />
      )}
      {showSkipConfirmModal && (
        <ConfirmPopUp
          title="Skip sign up step of the user"
          description={`You are about to skip sign up step for ${investorFullName}. Are you sure?`}
          onClose={() => setShowSkipConfirmModal(false)}
          onSubmit={skipSignUpStep}
        />
      )}
    </Container>
  );
};

export default UserDetail;
