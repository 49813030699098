import { createSlice } from "@reduxjs/toolkit";

import { KYCStatus } from "integration/api/UsersApi/models";
import { Currency } from "integration/api/DealsApi/models";

import { UserStatus } from "application/helpers/userStatuses";

import { InvestorState } from "./model";
import InvestorsActions from "./actions";

const defaultState: InvestorState = {
  investor: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: {
      id: 0,
      isoCode: "",
      alpha3: "",
      name: "",
    },
    dealCurrency: Currency.USD,
    investmentAnalytics: null,
    kycStatus: KYCStatus.NOT_STARTED,
    kycStatusDate: "",
    commitments: [],
    investment: null,
    dealTitle: "",
    registrationDate: "",
    userStatus: UserStatus.INACTIVE,
    deletedDate: "",
    startDateVerification: "",
    registrationStatus: null,
  },
};

const initialState = defaultState;

export const portfolioInvestorSlice = createSlice({
  name: "portfolioInvestor",
  initialState,
  reducers: {
    resetInvestorDetails: (state) => {
      state.investor = defaultState.investor;
    },
  },
  extraReducers: (builder) => {
    // get investor by id
    builder.addCase(
      InvestorsActions.getInvestorById.fulfilled,
      (state, action) => {
        state.investor = action.payload;
      }
    );
    // get deal investor by id
    builder.addCase(
      InvestorsActions.getDealInvestorById.fulfilled,
      (state, action) => {
        state.investor = action.payload;
      }
    );

    // changeInvestment
    builder.addCase(
      InvestorsActions.changeInvestment.fulfilled,
      (state, action) => {
        state.investor.investment = {
          ...state.investor.investment!,
          investmentAmount: action.payload.investmentAmount,
          fundraisingStatus: action.payload.fundraisingStatus,
        };
      }
    );
  },
});

export const PortfolioInvestorSliceAction = portfolioInvestorSlice.actions;
export default portfolioInvestorSlice.reducer;
